<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product/sportsticket' }">运动票列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-tabs v-model="activeName">
                <el-tab-pane label="基本信息" name="info">
                    <Info v-if="info" v-on:toedit="()=>{this.info = !this.info}"/>
                    <Edit v-if="!info" v-on:toinfo="()=>{this.info = !this.info}"/>
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>
</template>
<script>
import Info from './page/Info'
import Edit from './page/Edit'

export default {
    components: {
        Info,
        Edit,
    },
    data() {
        return {
            info: true,
            activeName: 'info'
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
