<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="产品名称">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="适用门店">
                        <el-select v-model="form.store_uuid"
                                   filterable
                                   placeholder="请选择门店"
                                   style="width: 100%"
                                   :disabled=!this.Tool.is_empty(this.good_uuid)
                                   @change="selectStore">
                            <el-option
                                    v-for="item in store_optionals"
                                    :key="item.store_uuid"
                                    :label="item.store_name"
                                    :value="item.store_uuid">
                                        <span style="float: left">{{ item.store_name }}
                                            <!--{{item.city}}{{item.county}}{{item.address}}-->
                                        </span>
                                <span style="float: right; color: #8492a6; font-size: 13px">
                                            【{{ item.company_name }}】
                                        </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="产品标签">
                        <div>
                            <el-select
                                    @change="add_tag"
                                    v-model="select_tag"
                                    filterable
                                    placeholder="请选择标签"
                                    style="width: 100%">
                                <el-option
                                        v-for="item in tag_list"
                                        :key="item.tag_uuid"
                                        :label="item.tag_name"
                                        :value="item.tag_name+'|'+item.tag_colour">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="form-tag_colour-div">
                            <el-tag
                                    :key="item.index"
                                    v-for="item in this.form.tag"
                                    class="form-tag_colour-tag"
                                    effect="dark"
                                    closable
                                    @close="tagClose(item)"
                                    :color="item.tag_colour">
                                {{ item.tag_name }}
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item label="产品视频">
                        <Qnupload v-model="form.video" type="video"/>
                        <el-checkbox v-model="form.video_carousel">视频放到轮播图</el-checkbox>
                    </el-form-item>
                    <el-form-item label="产品照片">
                        <Qnupload v-model="form.photo" :sum="5" :isclipper="true" :fixedNumber="[4,3]"/>
                        <span class="form-tip">图片尺寸1024*768 或 宽:高≈4:3</span>
                    </el-form-item>

                    <el-form-item label="产品原价￥">
                        <el-input-number v-model="form.originally_price" :step="0.01" :min="0"
                                         :max="999999"
                                         step-strictly
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="优惠价格">
                        <el-input-number v-model="form.promotion_price" :step="0.01" :min="0"
                                         :max="999999"
                                         step-strictly
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="时长/分钟">
                        <el-input type="number" v-model="form.duration"></el-input>
                    </el-form-item>

                </el-form>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><h3>购买须知</h3></el-col>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="有效期至">
                        <el-date-picker
                                style="width: 70%"
                                v-model="form.expiration_time"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="使用时间">
                        <el-time-picker
                                is-range
                                v-model="usage_time"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围"
                                format="HH 点 mm 分"
                                value-format="HH:mm">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="预约信息">
                        <span>提前</span>
                        <el-input-number v-model="form.advance_booking" controls-position="right"
                                         :min="0"
                                         :max="30"></el-input-number>
                        <span>天预约</span>
                    </el-form-item>
                    <el-form-item label="单次最多可购买">
                        <el-input-number v-model="form.fraction_limit" controls-position="right"
                                         :min="0"
                                         :max="30"></el-input-number>
                        <span>份</span>
                    </el-form-item>
                    <el-form-item label="库存数量">
                        <el-input-number v-model="form.stock" controls-position="right" :min="0"
                                         :max="1000"></el-input-number>
                    </el-form-item>
                    <el-form-item label="退款">
                        <el-switch
                                v-model="form.is_refund"
                                :active-value="1"
                                :inactive-value="0"
                        ></el-switch>
                    </el-form-item>
                    <el-form-item label="退款备注">
                        <el-input v-model="form.refund_remarks" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="简介">
                        <el-input v-model="form.introduce" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                v-if="is_auth('product.sportsticket.issave')"
                                @click="save()"
                                size="medium" type="primary">保存
                        </el-button>

                        <el-button size="medium"
                                   @click="()=>{ this.$emit('toinfo')}">返回
                        </el-button>

                    </el-form-item>
                </el-form>
            </el-col>

        </el-row>
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        // Wangeditor,
        Qnupload,
    },
    data() {
        return {
            select_tag: '',
            tag_list: [],
            loading: true,
            form: {
                video: '',
                photo: [],
                tag: [],
                price_indication: 0,
                store_uuid: '',
                promotion_price: 0,
                originally_price: 0,
                stock: 0,
                introduce: '',
                duration: '',
                advance_booking: '',
                fraction_limit: '',
                is_refund: 1,
                refund_remarks: '',
                expiration_time:'2022'
            },
            usage_time: [new Date(2022, 12, 31, 0, 0), new Date(2022, 12, 31, 23, 59)],
            video_carousel: false,    // 视频是否加入轮播图

            store_uuid: '',
            good_uuid: '',                  // 课表uuid
            store_optionals: [],            // 可选门店信息
            select_store_data: null,        // 选中的门店数据
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
        this.gettag()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getStore()         // 获取可选门店
            let good_uuid = this.$route.query.good_uuid
            console.log('good_uuid', good_uuid)
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getinfo(good_uuid)
            } else {
                this.loading = false
            }
        },
        // 获取标签
        gettag() {
            let postdata = {
                api_name: 'lesson.lesson.gettag',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tag_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|")
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
            }
            if (!this.tag_exist(tag.tag_name)) {
                this.form.tag.push(tag)
            }
            this.select_tag = ''
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.form.tag.length > 0) {
                for (let v of this.form.tag) {
                    if (tag_name == v.tag_name) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
        },
        // 删除标签
        tagClose(tag) {
            this.form.tag.splice(this.form.tag.indexOf(tag), 1);
        },
        // 选择门店
        selectStore(store_uuid) {
            for (let item of this.store_optionals) {
                if (item.store_uuid === store_uuid) {
                    this.select_store_data = item
                    break
                }
            }
        },
        // 获取可选门店
        getStore() {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getstore",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.store_optionals = json.data.list
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        //运动票详情
        getinfo(good_uuid) {
            let postdata = {
                api_name: "product.sportsticket.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid
            }
            console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                console.log('json', json)
                if (json.code === 0) {
                    this.form = json.data
                    this.store_uuid = json.data.store_uuid
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        //保存
        save() {
            let postdata = {
                api_name: "product.sportsticket.issave",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);
            if (!this.Tool.is_empty(this.usage_time)) {
                postdata.usage_time = this.usage_time[0] + '-' + this.usage_time[1]
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // 判断是否有课程id，如果有返回详情（编辑的情况），没有返回列表（添加的情况）
                            if (this.Tool.is_empty(this.good_uuid)) {
                                this.isreturn();        // 返回到列表
                            } else {
                                this.$emit('toinfo')    // 返回到详情
                            }
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.push({path: '/product/sportsticket'})
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
