<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="产品名称">
                        <span>{{ info.title }}</span>
                    </el-form-item>
                    <el-form-item label="适用门店">
                        <span>{{ info.store_name }}</span>
                    </el-form-item>
                    <el-form-item label="产品标签">
                        <div class="form-tag_colour-div">
                            <el-tag
                                    :key="item.index"
                                    v-for="item in info.tag"
                                    class="form-tag_colour-tag"
                                    effect="dark"
                                    :color="item.tag_colour">
                                {{ item.tag_name }}
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item label="产品视频">
                        <Qnupload v-model="info.video" type="video" :readonly='true'/>
                        <span v-if="info.video_carousel===1" class="form-span-text">视频放到轮播图</span>
                    </el-form-item>
                    <el-form-item label="产品照片">
                        <Qnupload v-model="info.photo" :sum="5" :readonly='true'/>
                    </el-form-item>
                    <el-form-item label="产品原价￥">
                        <span>{{ info.originally_price }}</span>
                    </el-form-item>
                    <el-form-item label="优惠价格">
                        <span>{{ info.promotion_price }}</span>
                    </el-form-item>
                    <el-form-item label="时长/分钟">
                        <span>{{ info.duration }}</span>
                    </el-form-item>

                </el-form>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><h3>购买须知</h3></el-col>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="有效期至">
                        <span>{{ info.expiration_time }}</span>
                    </el-form-item>
                    <el-form-item label="使用时间">
                        <span>{{ info.usage_time }}</span>
                    </el-form-item>
                    <el-form-item label="预约信息">
                        <span>提前 {{ info.advance_booking }}天预约</span>
                    </el-form-item>
                    <el-form-item label="单次最多可购买">
                        <span> {{ info.fraction_limit }}份</span>
                    </el-form-item>
                    <el-form-item label="库存数量">
                        <span> {{ info.stock }}</span>
                    </el-form-item>
                    <el-form-item label="退款">
                        <el-switch
                                v-model="info.is_refund"
                                :active-value="1"
                                :inactive-value="0"
                        ></el-switch>
                    </el-form-item>
                    <el-form-item label="退款备注">
                        <span> {{ info.refund_remarks }}</span>
                    </el-form-item>
                    <el-form-item label="简介">
                        <span> {{ info.introduce }}</span>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                v-if="is_auth('product.sportsticket.issave') && info.publish_state===0 && info.state===1"
                                @click="()=>{ this.$emit('toedit')}"
                                size="medium" type="primary">编辑
                        </el-button>
                        <el-button
                                v-if="is_auth('product.sportsticket.upapply')&& info.publish_state===0 && info.state===1"
                                @click="operation_tip(info.good_uuid,info.title,'upapply')"
                                type="success" size="medium">申请上架
                        </el-button>
                        <el-button
                                v-if="is_auth('product.sportsticket.withdraw')&& info.publish_state!==0 && info.state===1"
                                @click="operation_tip(info.good_uuid,info.title,'withdraw')"
                                type="warning" size="medium">下架撤回
                        </el-button>

                        <el-button size="medium"
                                   @click="isreturn">返回
                        </el-button>

                    </el-form-item>
                </el-form>
            </el-col>

        </el-row>
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        // Wangeditor,
        Qnupload,
    },
    data() {
        return {
            loading: true,
            info: {},
            good_uuid: '',
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let good_uuid = this.$route.query.good_uuid
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getinfo(good_uuid)
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({path: '/product/sportsticket'})    // 返回列表
                    }
                });

            }
        },
        //运动票详情
        getinfo() {
            let postdata = {
                api_name: "product.sportsticket.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid: this.good_uuid
            }
            console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                console.log('json', json)
                if (json.code === 0) {
                    this.info = json.data
                    this.store_uuid = json.data.store_uuid
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(good_uuid = '', title = '', operation = '') {

            let tip = ''
            let options = {}
            // 上架
            if (operation === 'upapply') {
                tip = '上架【' + title + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '下架【' + title + '】？'
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(good_uuid, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(good_uuid = '', operation = '') {
            let postdata = {
                api_name: "product.sportsticket." + operation,
                token: this.Tool.get_l_cache('token'),
                good_uuid: good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.push({path: '/product/sportsticket'})
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
